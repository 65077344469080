import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

import { Carousel } from '../../Carousel'
import styles from './brands.module.scss'
import { constants, FILTER_VEHICLE_TYPES_CARS, FILTER_VEHICLE_TYPES_VANS } from '@/lib/constants'
import { FILTERS } from '@/lib/filters'
import { useExperiment } from '@/lib/hooks/useExperiment'
import { getUrlReadyString } from '@/lib/utilities/vehicle'

export type BrandsProps = {
  isVans: boolean
}

export const Brands = ({ isVans }: BrandsProps) => {
  const [experimentGroup] = useExperiment()
  const [isVariant, setIsVariant] = useState(false)

  useEffect(() => {
    if (experimentGroup === 'popular-brands-carousel-variant') {
      setIsVariant(true)
    }
  }, [experimentGroup])

  return (
    <Carousel
      header={<h2>{isVariant ? 'Popular brands' : 'Brands A-Z'}</h2>}
      scrollValue={493}
      moreLink={isVans ? 'van-leasing/search' : 'car-leasing/search'}
      moreLabel="brands"
      sectionName="brands">
      {!isVans &&
        isVariant &&
        FILTERS.topManufacturers
          .filter(m => m.vehicleTypes.includes(FILTER_VEHICLE_TYPES_CARS))
          .map(item => (
            <Link
              href={`/${isVans ? 'van' : 'car'}-leasing/${getUrlReadyString(item.name)}`}
              key={item.name}>
              <a
                className={styles.link}
                data-name={item.name.toLowerCase()}
                data-needs-white={item.name !== 'Ford'}>
                <div className={styles.image}>
                  <Image
                    src={`${
                      constants.imageHost.assets
                    }/manufacturer-logos/symbol-only/${getUrlReadyString(item.name)}.png`}
                    alt={item.name}
                    height={56}
                    width={56}
                  />
                </div>
                <p className={styles.label}>{item.name}</p>
              </a>
            </Link>
          ))}
      {isVariant && !isVans && <span className={styles.spacer} />}
      {FILTERS.manufacturers
        .filter(m => {
          const type = isVans ? FILTER_VEHICLE_TYPES_VANS : FILTER_VEHICLE_TYPES_CARS
          return m.vehicleTypes.includes(type)
        })
        .map(item => {
          return (
            <Link
              href={`/${isVans ? 'van' : 'car'}-leasing/${getUrlReadyString(item.name)}`}
              key={item.name}>
              <a
                className={styles.link}
                data-name={item.name.toLowerCase()}
                data-needs-white={item.name !== 'Ford'}>
                <div className={styles.image}>
                  <Image
                    src={`${
                      constants.imageHost.assets
                    }/manufacturer-logos/symbol-only/${getUrlReadyString(item.name)}.png`}
                    alt={item.name}
                    height={56}
                    width={56}
                  />
                </div>
                <p className={styles.label}>{item.name}</p>
              </a>
            </Link>
          )
        })}
    </Carousel>
  )
}
